export const SET_LOADING = 'SET_LOADING';
export const SET_CURRENT = 'SET_CURRENT';
export const SET_NEXT_48_HOURS = 'SET_NEXT_48_HOURS';
export const SET_NEXT_7_DAYS = 'SET_NEXT_7_DAYS';
export const SET_PLACE = 'SET_PLACE';
export const SET_TIME_FORMAT = 'SET_TIME_FORMAT';
export const SET_UNIT = 'SET_UNIT';
export const SET_SETTINGS_OPEN = 'SET_SETTINGS_OPEN';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SET_BG_DOWNLOAD_ON_LOAD = 'SET_BG_DOWNLOAD_ON_LOAD';
